import Parse from "parse";




















export class MDZ_Slides extends Parse.Object {
  static __initStatic() {this.className = "MDZ_Slides"}

  constructor(data) {
    super("MDZ_Slides", data );
  }

  get content1() {
    return super.get("content1");
  }
  set content1(value) {
    super.set("content1", value);
  }
  get content2() {
    return super.get("content2");
  }
  set content2(value) {
    super.set("content2", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get headline() {
    return super.get("headline");
  }
  set headline(value) {
    super.set("headline", value);
  }
  get nextSlide() {
    return super.get("nextSlide");
  }
  set nextSlide(value) {
    super.set("nextSlide", value);
  }
  get picture1() {
    return super.get("picture1");
  }
  set picture1(value) {
    super.set("picture1", value);
  }
  get picture2() {
    return super.get("picture2");
  }
  set picture2(value) {
    super.set("picture2", value);
  }
  get schaufensterId() {
    return super.get("schaufensterId");
  }
  set schaufensterId(value) {
    super.set("schaufensterId", value);
  }
  get slideNumber() {
    return super.get("slideNumber");
  }
  set slideNumber(value) {
    super.set("slideNumber", value);
  }
  get subheadline() {
    return super.get("subheadline");
  }
  set subheadline(value) {
    super.set("subheadline", value);
  }
  get template() {
    return super.get("template");
  }
  set template(value) {
    super.set("template", value);
  }
} MDZ_Slides.__initStatic();

Parse.Object.registerSubclass("MDZ_Slides", MDZ_Slides);
