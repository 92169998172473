import Parse from "parse";














export class OD3_Maintenance_Media extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Media"}

  constructor(data) {
    super("OD3_Maintenance_Media", data );
  }

  get filename() {
    return super.get("filename");
  }
  set filename(value) {
    super.set("filename", value);
  }
  get media() {
    return super.get("media");
  }
  set media(value) {
    super.set("media", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Maintenance_Media.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Media", OD3_Maintenance_Media);
