import Parse from "parse";




















export class MDZ_SchaufensterExample extends Parse.Object {
  static __initStatic() {this.className = "MDZ_SchaufensterExample"}

  constructor(data) {
    super("MDZ_SchaufensterExample", data );
  }

  get content1() {
    return super.get("content1");
  }
  set content1(value) {
    super.set("content1", value);
  }
  get content2() {
    return super.get("content2");
  }
  set content2(value) {
    super.set("content2", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get headline() {
    return super.get("headline");
  }
  set headline(value) {
    super.set("headline", value);
  }
  get imgSrc() {
    return super.get("imgSrc");
  }
  set imgSrc(value) {
    super.set("imgSrc", value);
  }
  get nextSlide() {
    return super.get("nextSlide");
  }
  set nextSlide(value) {
    super.set("nextSlide", value);
  }
  get picture1() {
    return super.get("picture1");
  }
  set picture1(value) {
    super.set("picture1", value);
  }
  get picture2() {
    return super.get("picture2");
  }
  set picture2(value) {
    super.set("picture2", value);
  }
  get subheadline() {
    return super.get("subheadline");
  }
  set subheadline(value) {
    super.set("subheadline", value);
  }
  get template() {
    return super.get("template");
  }
  set template(value) {
    super.set("template", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
} MDZ_SchaufensterExample.__initStatic();

Parse.Object.registerSubclass("MDZ_SchaufensterExample", MDZ_SchaufensterExample);
