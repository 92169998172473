import Parse from "parse";




















export class OD3_Maintenance_Ticket extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Ticket"}

  constructor(data) {
    super("OD3_Maintenance_Ticket", data );
  }

  get assignedgroups() {
    return super.relation("assignedgroups");
  }
  get assignedusers() {
    return super.relation("assignedusers");
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Ticket.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Ticket", OD3_Maintenance_Ticket);
