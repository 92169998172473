const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\minimap\\minimap.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import React, { useEffect, useRef, useState } from "react";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import debounce from "lodash.debounce";
import { Button, Tooltip } from "antd";
import Parse from "parse";







const Minimap = ({ layerUrls, bbox, updateBbox }) => {
  const [legendUrl, setLegendUrl] = useState(null);
  const mapRef = useRef(null);
  const layerRefs = useRef([]);
  const mapContainerRef = useRef(null);

  const appendBboxToUrl = (url, bbox) => {
    const urlObj = new URL(url);
    if (urlObj.searchParams.get("bbox") !== bbox) {
      urlObj.searchParams.set("bbox", bbox);
    }
    return urlObj.toString();
  };

  useEffect(() => {
    // Fetch the legend image URL from Parse Server
    const fetchLegendUrl = async () => {
      const LegendClass = Parse.Object.extend("AD4GD_Lake_Layers");
      const query = new Parse.Query(LegendClass);
      query.ascending("createdAt");
      const result = await query.first();

      if (result) {
        const imageUrl = result.get("legendUrl");
        setLegendUrl(imageUrl._url);
        console.log(imageUrl);
      } else {
        console.error("No legend URL found.");
      }
    };

    fetchLegendUrl().catch((error) =>
      console.error("Error fetching legend URL:", error)
    );
  }, []);

  useEffect(() => {
    if (mapContainerRef.current) {
      const bboxValues = bbox.split(",").map(Number);
      const maxBounds = L.latLngBounds(
        L.latLng(bboxValues[0], bboxValues[1]),
        L.latLng(bboxValues[2], bboxValues[3])
      );

      if (!mapRef.current) {
        const minimap = L.map(mapContainerRef.current, {
          center: maxBounds.getCenter(),
          zoom: 16,
          minZoom: 13,
          maxZoom: 20,
          maxBounds,
          worldCopyJump: true,
        });

        L.tileLayer(
          "https://cartodb-basemaps-{s}.global.ssl.fastly.net/light_all/{z}/{x}/{y}.png"
        ).addTo(minimap);

        mapRef.current = minimap;
      }

      const minimap = mapRef.current;

      _optionalChain([minimap, 'optionalAccess', _ => _.setMaxBounds, 'call', _2 => _2(maxBounds)]);

      const loadLayers = debounce(() => {
        if (minimap) {
          const bounds = minimap.getBounds();
          const newBbox = [
            bounds.getSouth(),
            bounds.getWest(),
            bounds.getNorth(),
            bounds.getEast(),
          ].join(",");

          updateBbox(newBbox);

          layerRefs.current.forEach((layer) => {
            layer.remove();
          });
          layerRefs.current = [];

          layerUrls.forEach((url) => {
            const minimapLayer = L.imageOverlay(
              appendBboxToUrl(url, newBbox),
              bounds
            );
            minimapLayer.addTo(minimap);
            layerRefs.current.push(minimapLayer);
          });
        }
      }, 300);

      loadLayers();
      minimap.on("zoomend moveend", loadLayers);

      return () => {
        minimap.off("zoomend moveend", loadLayers);
      };
    }
  }, [layerUrls, bbox, updateBbox]);

  return (
    React.createElement('div', { style: { position: "relative" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 117}}
      , React.createElement('div', {
        ref: mapContainerRef,
        style: {
          height: "400px",
          width: "100%",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 118}}
)

      , React.createElement(Tooltip, {
        title: 
          legendUrl ? (
            React.createElement('img', {
              src: legendUrl,
              alt: "Legend",
              style: {
                width: "300px",
                height: "auto",
                display: "block",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
            )
          ) : (
            "Loading legend..."
          )
        ,
        placement: "right",
        trigger: "click", __self: this, __source: {fileName: _jsxFileName, lineNumber: 126}}

        , React.createElement(Button, {
          type: "primary",
          style: {
            position: "absolute",
            top: "20px",
            right: "20px",
            zIndex: 1000,
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 145}}
, "i"

        )
      )
    )
  );
};

export default Minimap;
