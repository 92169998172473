import Parse from "parse";




















export class AD4GD_LakeMetaData extends Parse.Object {
  static __initStatic() {this.className = "AD4GD_LakeMetaData"}

  constructor(data) {
    super("AD4GD_LakeMetaData", data );
  }

  get area() {
    return super.get("area");
  }
  set area(value) {
    super.set("area", value);
  }
  get circumference() {
    return super.get("circumference");
  }
  set circumference(value) {
    super.set("circumference", value);
  }
  get district() {
    return super.get("district");
  }
  set district(value) {
    super.set("district", value);
  }
  get geography() {
    return super.get("geography");
  }
  set geography(value) {
    super.set("geography", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get swimmingUsage() {
    return super.get("swimmingUsage");
  }
  set swimmingUsage(value) {
    super.set("swimmingUsage", value);
  }
  get volume() {
    return super.get("volume");
  }
  set volume(value) {
    super.set("volume", value);
  }
  get averageDepth() {
    return super.get("averageDepth");
  }
  set averageDepth(value) {
    super.set("averageDepth", value);
  }
  get maximumDepth() {
    return super.get("maximumDepth");
  }
  set maximumDepth(value) {
    super.set("maximumDepth", value);
  }
} AD4GD_LakeMetaData.__initStatic();

Parse.Object.registerSubclass("AD4GD_LakeMetaData", AD4GD_LakeMetaData);
