import Parse from "parse";





















export class OD3_AlarmAction extends Parse.Object {
  static __initStatic() {this.className = "OD3_AlarmAction"}

  constructor(data) {
    super("OD3_AlarmAction", data );
  }

  get extra() {
    return super.get("extra");
  }
  set extra(value) {
    super.set("extra", value);
  }
  get formFields() {
    return super.get("formFields");
  }
  set formFields(value) {
    super.set("formFields", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get payload() {
    return super.get("payload");
  }
  set payload(value) {
    super.set("payload", value);
  }
  get supportedTypes() {
    return super.get("supportedTypes");
  }
  set supportedTypes(value) {
    super.set("supportedTypes", value);
  }
  get target() {
    return super.get("target");
  }
  set target(value) {
    super.set("target", value);
  }
  get templateType() {
    return super.get("templateType");
  }
  set templateType(value) {
    super.set("templateType", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get topic() {
    return super.get("topic");
  }
  set topic(value) {
    super.set("topic", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
} OD3_AlarmAction.__initStatic();

Parse.Object.registerSubclass("OD3_AlarmAction", OD3_AlarmAction);
