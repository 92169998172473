import Parse from "parse";














export class OD3_UserData extends Parse.Object {
  static __initStatic() {this.className = "OD3_UserData"}

  constructor(data) {
    super("OD3_UserData", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_UserData.__initStatic();

Parse.Object.registerSubclass("OD3_UserData", OD3_UserData);
