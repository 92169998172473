import Parse from "parse";



















export class OD3_Maintenance_Ticket_Source extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Ticket_Source"}

  constructor(data) {
    super("OD3_Maintenance_Ticket_Source", data );
  }

  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get ticket() {
    return super.get("ticket");
  }
  set ticket(value) {
    super.set("ticket", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Ticket_Source.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Ticket_Source", OD3_Maintenance_Ticket_Source);
