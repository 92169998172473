import Parse from "parse";
















export class OD3_Maintenance_Message extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Message"}

  constructor(data) {
    super("OD3_Maintenance_Message", data );
  }

  get classname() {
    return super.get("classname");
  }
  set classname(value) {
    super.set("classname", value);
  }
  get data() {
    return super.get("data");
  }
  set data(value) {
    super.set("data", value);
  }
  get referencedObjectId() {
    return super.get("referencedObjectId");
  }
  set referencedObjectId(value) {
    super.set("referencedObjectId", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Message.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Message", OD3_Maintenance_Message);
