import Parse from "parse";















export class OD3_Config extends Parse.Object {
  static __initStatic() {this.className = "OD3_Config"}

  constructor(data) {
    super("OD3_Config", data );
  }

  get key() {
    return super.get("key");
  }
  set key(value) {
    super.set("key", value);
  }
  get priority() {
    return super.get("priority");
  }
  set priority(value) {
    super.set("priority", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get value() {
    return super.get("value");
  }
  set value(value) {
    super.set("value", value);
  }
} OD3_Config.__initStatic();

Parse.Object.registerSubclass("OD3_Config", OD3_Config);
