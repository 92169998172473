const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\NotFoundPage\\notFound.tsx";import React from "react";
import { Button, ConfigProvider, Result, Row } from "antd";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { useNavigate } from "@opendash/router";

const NotFound = () => {
  const navigate = useNavigate();

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 11}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 12}}
)
      )

      , React.createElement(Result, {
        status: "warning",
        title: "Die Seite konnte nicht gefunden werden."     ,
        extra: 
          React.createElement(ConfigProvider, {
            wave: { disabled: true },
            theme: {
              token: {
                colorPrimary: "#96F5D0",
                colorTextLightSolid: "fff",
                borderRadius: 6,
                fontSize: 16,
              },
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 23}}

            , React.createElement(Button, {
              type: "primary",
              size: "large",
              onClick: () => {
                navigate("/home");
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 34}}
, "Zurück zur Startseite"

            )
          )
        , __self: this, __source: {fileName: _jsxFileName, lineNumber: 19}}
      )
    )
  );
};

export default NotFound;
