import Parse from "parse";
















export class OD3_Maintenance_SourceMeta extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_SourceMeta"}

  constructor(data) {
    super("OD3_Maintenance_SourceMeta", data );
  }

  get item() {
    return super.get("item");
  }
  set item(value) {
    super.set("item", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Maintenance_SourceMeta.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_SourceMeta", OD3_Maintenance_SourceMeta);
