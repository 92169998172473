import Parse from "parse";














export class OD3_Core_Email extends Parse.Object {
  static __initStatic() {this.className = "OD3_Core_Email"}

  constructor(data) {
    super("OD3_Core_Email", data );
  }

  get error() {
    return super.get("error");
  }
  set error(value) {
    super.set("error", value);
  }
  get payload() {
    return super.get("payload");
  }
  set payload(value) {
    super.set("payload", value);
  }
  get response() {
    return super.get("response");
  }
  set response(value) {
    super.set("response", value);
  }
  get sent() {
    return super.get("sent");
  }
  set sent(value) {
    super.set("sent", value);
  }
  get success() {
    return super.get("success");
  }
  set success(value) {
    super.set("success", value);
  }
} OD3_Core_Email.__initStatic();

Parse.Object.registerSubclass("OD3_Core_Email", OD3_Core_Email);
