import Parse from "parse";














export class OD3_Maintenance_Item extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Item"}

  constructor(data) {
    super("OD3_Maintenance_Item", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Maintenance_Item.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Item", OD3_Maintenance_Item);
