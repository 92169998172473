import Parse from "parse";














export class AD4GD_LakeImages extends Parse.Object {
  static __initStatic() {this.className = "AD4GD_LakeImages"}

  constructor(data) {
    super("AD4GD_LakeImages", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get image() {
    return super.get("image");
  }
  set image(value) {
    super.set("image", value);
  }
  get lake() {
    return super.get("lake");
  }
  set lake(value) {
    super.set("lake", value);
  }
} AD4GD_LakeImages.__initStatic();

Parse.Object.registerSubclass("AD4GD_LakeImages", AD4GD_LakeImages);
