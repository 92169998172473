import Parse from "parse";















export class OD3_NavigationGroup extends Parse.Object {
  static __initStatic() {this.className = "OD3_NavigationGroup"}

  constructor(data) {
    super("OD3_NavigationGroup", data );
  }

  get icon() {
    return super.get("icon");
  }
  set icon(value) {
    super.set("icon", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_NavigationGroup.__initStatic();

Parse.Object.registerSubclass("OD3_NavigationGroup", OD3_NavigationGroup);
