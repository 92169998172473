const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\lakeStats\\lakeStats.tsx"; function _optionalChain(ops) { let lastAccessLHS = undefined; let value = ops[0]; let i = 1; while (i < ops.length) { const op = ops[i]; const fn = ops[i + 1]; i += 2; if ((op === 'optionalAccess' || op === 'optionalCall') && value == null) { return undefined; } if (op === 'access' || op === 'optionalAccess') { lastAccessLHS = value; value = fn(value); } else if (op === 'call' || op === 'optionalCall') { value = fn((...args) => value.call(lastAccessLHS, ...args)); lastAccessLHS = undefined; } } return value; }import { useTranslation } from "@opendash/core";
import { Col, Row, Typography } from "antd";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import React, { useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "@opendash/router";
import { StarFilled } from "@ant-design/icons";

import { useLakeMetaData } from "../../hooks/useLakeMetaData";
import Parse from "parse";
import MultiColorBar from "../multicolorbar/multicolorbar";
import CustomCarousel from "../carousel/carousel";











const IconLabelComponent = ({
  icon: Icon,
  label,
}) => {
  return (
    React.createElement('div', { style: { display: "flex", alignItems: "center", marginTop: "4%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
      , React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 29}}
        , React.createElement(Icon, {
          style: {
            fontSize: "20px",
            padding: "6px",
            backgroundColor: "#56ECAD",
            borderRadius: "50%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 30}}
        )
      )
      , React.createElement(Text, { style: { fontSize: "16px", fontWeight: "600", marginLeft: "5%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 39}}
        , label
      )
    )
  );
};

const { Title, Text } = Typography;

const PropertyRow = ({ label, value }) => (
  React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}
    , React.createElement(Text, { style: { flex: 0.35, fontSize: "15px", fontWeight: "600" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 50}}
      , label, ":"
    )
    , React.createElement(Text, { style: { flex: 0.65, fontSize: "15px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 53}}, value)
  )
);






const LakeStats = ({}) => {
  const { lakeId } = useParams();
  const location = useLocation();
  const [mapItems, setMapItems] = useState([]);
  const [bbox, setBbox] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");
  const {
    item: { sensors },
  } = location.state || {};

  const t = useTranslation();

  const updateBbox = (newBbox) => {
    setBbox(newBbox);
  };

  useEffect(() => {
    // Fetch bbox and other details from Parse server
    const fetchBoundingBox = async () => {
      setLoading(true);
      setError("");

      try {
        const query = new Parse.Query("MIAAS_Geographies");
        query.equalTo("objectId", lakeId);
        const result = await query.first();

        if (result) {
          const bbox = result.get("bbox");
          const newMapItems = [
            {
              layerUrls: [
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/k_fb_btwert?service=wms&request=getmap&version=1.3.0&layers=0&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/k_fb_btwert?service=wms&request=getmap&version=1.3.0&layers=1&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
              ],
              title: "Biotoptypen: Biotopwerte",
            },
            {
              layerUrls: [
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/k06_05gruenversorg2016?service=wms&request=getmap&version=1.3.0&layers=0&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/k06_05gruenversorg2016?service=wms&request=getmap&version=1.3.0&layers=1&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/k06_05gruenversorg2016?service=wms&request=getmap&version=1.3.0&layers=2&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
              ],
              title:
                "Versorgung mit öffentlichen, wohnungsnahen Grünanlagen 2016",
            },
            {
              layerUrls: [
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=0&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=1&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=2&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=3&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=4&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=5&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=6&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=7&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=11&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
                `https://fbinter.stadt-berlin.de/fb/wms/senstadt/nsg_lsg?service=wms&request=getmap&version=1.3.0&layers=12&styles=&crs=EPSG:4326&bbox=${bbox}&width=1598&height=952&format=image/png&transparent=true`,
              ],
              title:
                "Schutzgebiete und Schutzobjekte nach Naturschutzrecht Berlin (inklusive Natura 2000)",
            },
          ];

          setMapItems(newMapItems);
          setBbox(bbox);
        } else {
          setError("Lake data not found.");
        }
      } catch (err) {
        setError("Failed to fetch bounding box data.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchBoundingBox();
  }, [lakeId]);

  const { result: properties } = useLakeMetaData();

  const currentLake = properties.find(
    (item) => _optionalChain([item, 'access', _ => _.geography, 'optionalAccess', _2 => _2.id]) === lakeId
  ) || {
    id: undefined,
    name: "",
    area: 0,
    swimmingUsage: false,
    district: "",
    circumference: 0,
    volume: 0,
    averageDepth: 0,
    maximumDepth: 0,
  };

  const [wqiValues, setWqiValues] = useState({
    minVal: 0,
    maxVal: 100,
    minTrend: 0,
    maxTrend: 100,
    value: 0,
    trend: 0,
  });

  const getWQI = async () => {
    if (lakeId) {
      // Query to get the maximum value
      const wqiQuery = new Parse.Query("AD4GD_Lake_Quality_Index");

      // Get the maximum value (descending order)
      const maxResults = await wqiQuery
        .select("WQI_status")
        .descending("WQI_status")
        .limit(1)
        .find();

      // Get the minimum value (ascending order)
      const minResults = await wqiQuery
        .select("WQI_status")
        .ascending("WQI_status")
        .limit(1)
        .find();

      const maxResultsTrend = await wqiQuery
        .select("WQI_trend")
        .descending("WQI_trend")
        .limit(1)
        .find();

      const minResultsTrend = await wqiQuery
        .select("WQI_trend")
        .ascending("WQI_trend")
        .limit(1)
        .find();

      const round = (value, decimals) =>
        value !== null ? parseFloat(value.toFixed(decimals)) : null;

      const maxValue =
        maxResults.length > 0
          ? round(maxResults[0].get("WQI_status"), 2)
          : null;

      const minValue =
        minResults.length > 0
          ? round(minResults[0].get("WQI_status"), 2)
          : null;

      const maxTrend =
        maxResultsTrend.length > 0
          ? round(maxResultsTrend[0].get("WQI_trend"), 2)
          : null;

      const minTrend =
        minResultsTrend.length > 0
          ? round(minResultsTrend[0].get("WQI_trend"), 2)
          : null;

      // Query to get the value of current lake
      const query = new Parse.Query("AD4GD_Lake_Quality_Index");
      query.equalTo("lake", currentLake.name);
      const results = await query.find();

      if (results.length > 0) {
        const lakeObject = results[0];
        const wqiStatus = round(lakeObject.get("WQI_status"), 2);
        const wqiTrend = round(lakeObject.get("WQI_trend"), 2);

        // Set the state with the rounded values
        setWqiValues({
          minVal: minValue || 0,
          maxVal: maxValue || 100,
          minTrend: minTrend || 0,
          maxTrend: maxTrend || 100,
          value: wqiStatus || 0,
          trend: wqiTrend || 0,
        });
      } else {
        console.log(`No lake found with the name: ${currentLake.name}`);
      }
    }
  };

  useEffect(() => {
    if (currentLake.name) {
      getWQI();
    }
  }, [currentLake.name]);

  const config = useMemo(() => {
    return {
      _sources: [],
      _items: [],
      _dimensions: [...sensors],
      _history: {},
    };
  }, [sensors]);

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 264}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 265}}
)
      )
      , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 271}}
        , React.createElement('div', {
          style: {
            width: "30%",
            backgroundColor: "white",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 272}}

          , React.createElement(Title, {
            level: 1,
            style: {
              fontWeight: "bold",
              marginBottom: "2%",
              paddingLeft: "1rem",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 278}}

            , currentLake.name
          )
          , React.createElement(Row, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 288}}
            , React.createElement(Col, {
              style: {
                alignSelf: "center",
                paddingLeft: "1rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 289}}

              , React.createElement('svg', { width: "12", height: "12", __self: this, __source: {fileName: _jsxFileName, lineNumber: 295}}
                , React.createElement('circle', { cx: "6", cy: "6", r: "6", fill: "#55b169", __self: this, __source: {fileName: _jsxFileName, lineNumber: 296}} )
              )
              , React.createElement(Text, {
                strong: true,
                style: {
                  lineHeight: 0,
                  fontSize: "15px",
                  marginLeft: "5px",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 298}}

                , currentLake.name
              )
            )
          )

          /* ########################################################### */
          , React.createElement(CustomCarousel, { maps: mapItems, bbox: bbox, updateBbox: updateBbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 312}} )

          , React.createElement('div', { style: { marginTop: "6%", paddingLeft: "1rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 314}}
            , React.createElement(PropertyRow, { label: t("Name"), value: currentLake.name, __self: this, __source: {fileName: _jsxFileName, lineNumber: 315}} )
            , React.createElement(PropertyRow, { label: t("Fläche"), value: currentLake.area + " m²", __self: this, __source: {fileName: _jsxFileName, lineNumber: 316}} )
            , React.createElement(PropertyRow, {
              label: t("Badenutzung"),
              value: currentLake.swimmingUsage ? "Ja" : "Nein", __self: this, __source: {fileName: _jsxFileName, lineNumber: 317}}
            )
            , React.createElement(PropertyRow, { label: t("Bezirk"), value: currentLake.district, __self: this, __source: {fileName: _jsxFileName, lineNumber: 321}} )
            , React.createElement(PropertyRow, {
              label: t("Umfang"),
              value: currentLake.circumference + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 322}}
            )
            , React.createElement(PropertyRow, {
              label: t("Volumen"),
              value: currentLake.volume + " m³", __self: this, __source: {fileName: _jsxFileName, lineNumber: 326}}
            )
            , React.createElement(PropertyRow, {
              label: t("Durchnittliche Tiefe"),
              value: currentLake.averageDepth + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 330}}
            )
            , React.createElement(PropertyRow, {
              label: t("Maximale Tiefe"),
              value: currentLake.maximumDepth + " m", __self: this, __source: {fileName: _jsxFileName, lineNumber: 334}}
            )
          )

          , React.createElement(IconLabelComponent, {
            icon: StarFilled,
            label: "Als Favorit hinzufügen"  , __self: this, __source: {fileName: _jsxFileName, lineNumber: 340}}
          )
        )

        , React.createElement('div', {
          style: {
            width: "68%",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-end",
            gap: "2%",
            marginLeft: "1%",
            marginTop: "1%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 346}}

          , React.createElement('div', {
            style: {
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
              gap: "1%",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 358}}

            , React.createElement('div', {
              style: {
                width: "100%",
                height: "40%",
                marginBottom: "1%",
                borderRadius: "20px",
                backgroundColor: "white",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 367}}

              , React.createElement(Typography.Title, {
                level: 4,
                style: {
                  fontWeight: "bold",
                  paddingTop: "2%",
                  paddingLeft: "4%",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 376}}
, "Earth Observation Trophic State"

              )
              , React.createElement('div', { style: { width: "100%", height: "100%", margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 386}}
                , React.createElement('div', {
                  style: {
                    padding: "5% 15% 15% 15%",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 387}}

                  , wqiValues.value !== 0 ? (
                    React.createElement(React.Fragment, null
                      , React.createElement('div', {
                        style: {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 394}}

                        , React.createElement('div', {
                          style: {
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 402}}

                          , React.createElement('span', {
                            style: { fontSize: "20px", fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 409}}
, "WQI State: "
                              , wqiValues.value
                          )
                        )
                      )
                      , React.createElement(MultiColorBar, {
                        minValue: wqiValues.minVal,
                        maxValue: wqiValues.maxVal,
                        value: wqiValues.value, __self: this, __source: {fileName: _jsxFileName, lineNumber: 416}}
                      )
                    )
                  ) : (
                    React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 423}}, "Loading WQI data..."  )
                  )
                )
              )
            )

            , React.createElement('div', {
              style: {
                width: "100%",
                height: "40%",
                marginBottom: "1%",
                borderRadius: "20px",
                backgroundColor: "white",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 429}}

              , React.createElement(Typography.Title, {
                level: 4,
                style: {
                  fontWeight: "bold",
                  paddingTop: "2%",
                  paddingLeft: "4%",
                }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 438}}
, "Earth Observation Trophic Trend"

              )
              , React.createElement('div', { style: { width: "100%", height: "100%", margin: "0 auto" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 448}}
                , React.createElement('div', {
                  style: {
                    padding: "5% 15% 15% 15%",
                  }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 449}}

                  , wqiValues.value !== 0 ? (
                    React.createElement(React.Fragment, null
                      , React.createElement('div', {
                        style: {
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          marginBottom: "10px",
                        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 456}}

                        , React.createElement('div', {
                          style: {
                            display: "flex",
                            alignItems: "center",
                            marginBottom: "1rem",
                          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 464}}

                          , React.createElement('span', {
                            style: { fontSize: "20px", fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 471}}
, "WQI Trend: "
                              , wqiValues.trend
                          )
                        )
                      )
                      , React.createElement(MultiColorBar, {
                        minValue: wqiValues.minTrend,
                        maxValue: wqiValues.maxTrend,
                        value: wqiValues.trend, __self: this, __source: {fileName: _jsxFileName, lineNumber: 478}}
                      )
                    )
                  ) : (
                    React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 485}}, "Loading WQI data..."  )
                  )
                )
              )
            )
          )

          , React.createElement('div', {
            style: {
              width: "100%",
              borderRadius: "20px",
              backgroundColor: "white",
              padding: "1% 2%",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 492}}

            , React.createElement(WidgetStatic, {
              type: "lakeStats-widget",
              config: config, __self: this, __source: {fileName: _jsxFileName, lineNumber: 500}}
)
          )
        )
      )
    )
  );
};

export default LakeStats;
