import Parse from "parse";
























export class OD3_Maintenance_Schedule_Execution extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Schedule_Execution"}

  constructor(data) {
    super("OD3_Maintenance_Schedule_Execution", data );
  }

  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get finishedAt() {
    return super.get("finishedAt");
  }
  set finishedAt(value) {
    super.set("finishedAt", value);
  }
  get media() {
    return super.relation("media");
  }
  get origin() {
    return super.get("origin");
  }
  set origin(value) {
    super.set("origin", value);
  }
  get source() {
    return super.get("source");
  }
  set source(value) {
    super.set("source", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get title() {
    return super.get("title");
  }
  set title(value) {
    super.set("title", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Schedule_Execution.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Schedule_Execution", OD3_Maintenance_Schedule_Execution);
