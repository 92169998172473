import Parse from "parse";











export class OD3_Log extends Parse.Object {
  static __initStatic() {this.className = "OD3_Log"}

  constructor(data) {
    super("OD3_Log", data );
  }

  get references() {
    return super.get("references");
  }
  set references(value) {
    super.set("references", value);
  }
  get type() {
    return super.get("type");
  }
  set type(value) {
    super.set("type", value);
  }
} OD3_Log.__initStatic();

Parse.Object.registerSubclass("OD3_Log", OD3_Log);
