import Parse from "parse";



















export class OD3_Alarm extends Parse.Object {
  static __initStatic() {this.className = "OD3_Alarm"}

  constructor(data) {
    super("OD3_Alarm", data );
  }

  get action() {
    return super.get("action");
  }
  set action(value) {
    super.set("action", value);
  }
  get condition() {
    return super.get("condition");
  }
  set condition(value) {
    super.set("condition", value);
  }
  get item_dimension() {
    return super.get("item_dimension");
  }
  set item_dimension(value) {
    super.set("item_dimension", value);
  }
  get item_id() {
    return super.get("item_id");
  }
  set item_id(value) {
    super.set("item_id", value);
  }
  get item_source() {
    return super.get("item_source");
  }
  set item_source(value) {
    super.set("item_source", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get trigger() {
    return super.get("trigger");
  }
  set trigger(value) {
    super.set("trigger", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Alarm.__initStatic();

Parse.Object.registerSubclass("OD3_Alarm", OD3_Alarm);
