import Parse from "parse";
















export class MDZ_Schaufenster extends Parse.Object {
  static __initStatic() {this.className = "MDZ_Schaufenster"}

  constructor(data) {
    super("MDZ_Schaufenster", data );
  }

  get content() {
    return super.get("content");
  }
  set content(value) {
    super.set("content", value);
  }
  get description() {
    return super.get("description");
  }
  set description(value) {
    super.set("description", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get firstSlide() {
    return super.get("firstSlide");
  }
  set firstSlide(value) {
    super.set("firstSlide", value);
  }
  get imgSrc() {
    return super.get("imgSrc");
  }
  set imgSrc(value) {
    super.set("imgSrc", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get tOptions() {
    return super.get("tOptions");
  }
  set tOptions(value) {
    super.set("tOptions", value);
  }
} MDZ_Schaufenster.__initStatic();

Parse.Object.registerSubclass("MDZ_Schaufenster", MDZ_Schaufenster);
