const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\testFIS\\testFIS.tsx";import React, { useState, useEffect, useRef } from "react";
import L from "leaflet";
import "leaflet/dist/leaflet.css";
import { Select, Slider } from "antd";

const { Option } = Select;

const availableTimes = [
  "1987-01-01T00:00:00.000Z",
  "1992-01-01T00:00:00.000Z",
  "1997-01-01T00:00:00.000Z",
  "2002-01-01T00:00:00.000Z",
  "2007-01-01T00:00:00.000Z",
  "2012-01-01T00:00:00.000Z",
  "2017-01-01T00:00:00.000Z",
  "2022-01-01T00:00:00.000Z",
];

const propertyOptions = [
  "Aquatic",
  "Forest",
  "HerbaceousCrops",
  "WoodyCrops",
  "Shrublands",
];

const WMSClient = () => {
  const [imageSrc, setImageSrc] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [properties, setProperties] = useState("Forest"); // Default dropdown value
  const [time, setTime] = useState(availableTimes[6]); // Default slider value (2017)
  const mapRef = useRef(null);

  // Fetch image from the server
  const fetchImage = async () => {
    setLoading(true);
    setError("");
    try {
      const response = await fetch(
        `http://localhost:3000/proxy?time=${time}&properties=${properties}`
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const blob = await response.blob();
      const imageUrl = URL.createObjectURL(blob);
      setImageSrc(imageUrl);
    } catch (err) {
      console.error("Error fetching the image:", err);
      setError("Error fetching the image.");
    } finally {
      setLoading(false);
    }
  };

  // Fetch image initially and when properties or time changes
  useEffect(() => {
    fetchImage();
  }, [properties, time]); // This will fetch image initially and whenever properties or time changes

  useEffect(() => {
    let map;
    if (imageSrc && !error && mapRef.current) {
      if (!mapRef.current._leaflet_id) {
        // Only initialize the map if it's not already initialized
        map = L.map(mapRef.current).setView([41.7, 1.7], 7);

        // Add a base tile layer
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          maxZoom: 19,
        }).addTo(map);

        // Define the bounds for the image overlay
        const imageBounds = [
          [40.5122386211880907512, 0.06467085164777338],
          [42.88455540408618, 3.3621815323902170776],
        ];

        // Create an image overlay and add it to the map
        L.imageOverlay(imageSrc, imageBounds).addTo(map);
      }
    }

    // Cleanup function to remove the map
    return () => {
      if (map) {
        map.remove();
      }
    };
  }, [imageSrc, error]);

  const handlePropertyChange = (value) => {
    setProperties(value); // Update property and trigger image fetch in useEffect
  };

  const handleTimeChange = (value) => {
    setTime(availableTimes[value]); // Update time and trigger image fetch in useEffect
  };

  return (
    React.createElement('div', {
      style: {
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
      }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 102}}

      , React.createElement('h2', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 111}}, "ODC Test on Leaflet Map"    )

      /* Ant Design Dropdown for Properties */
      , React.createElement('div', { style: { marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}
        , React.createElement(Select, {
          defaultValue: properties, // Set default value from state
          style: { width: 200 },
          onChange: handlePropertyChange, __self: this, __source: {fileName: _jsxFileName, lineNumber: 115}}

          , propertyOptions.map((property) => (
            React.createElement(Option, { key: property, value: property, __self: this, __source: {fileName: _jsxFileName, lineNumber: 121}}
              , property
            )
          ))
        )
      )

      /* Ant Design Slider for Time */
      , React.createElement('div', { style: { marginBottom: 20 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}
        , React.createElement(Slider, {
          min: 0,
          max: availableTimes.length - 1,
          defaultValue: 6, // Default to 2017 (slider index)
          onChange: handleTimeChange,
          marks: {
            0: "1987",
            1: "1992",
            2: "1997",
            3: "2002",
            4: "2007",
            5: "2012",
            6: "2017",
            7: "2022",
          },
          step: 1,
          style: { width: 400 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 130}}
        )
      )

      /* Loading and Error Messages inside the map area */
      , React.createElement('div', {
        id: "map-container",
        style: {
          position: "relative",
          height: "600px",
          width: "60%",
          margin: "30px auto",
        }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 151}}

        , loading ? (
          React.createElement('p', {
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 161}}
, "Loading image..."

          )
        ) : error ? (
          React.createElement('p', {
            style: {
              position: "absolute",
              top: "50%",
              left: "50%",
              transform: "translate(-50%, -50%)",
              color: "red",
            }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 172}}

            , error
          )
        ) : (
          React.createElement('div', {
            id: "map",
            ref: mapRef,
            style: { height: "100%", width: "100%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 184}}
)
        )
      )
    )
  );
};

export default WMSClient;
