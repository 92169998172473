import Parse from "parse";














export class OD3_Monitoring_Slideshow extends Parse.Object {
  static __initStatic() {this.className = "OD3_Monitoring_Slideshow"}

  constructor(data) {
    super("OD3_Monitoring_Slideshow", data );
  }

  get dashboards() {
    return super.relation("dashboards");
  }
  get interval() {
    return super.get("interval");
  }
  set interval(value) {
    super.set("interval", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
} OD3_Monitoring_Slideshow.__initStatic();

Parse.Object.registerSubclass("OD3_Monitoring_Slideshow", OD3_Monitoring_Slideshow);
