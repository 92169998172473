import Parse from "parse";



















export class OD3_Maintenance_Ticket_Issuecategory extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Ticket_Issuecategory"}

  constructor(data) {
    super("OD3_Maintenance_Ticket_Issuecategory", data );
  }

  get issuecategory() {
    return super.get("issuecategory");
  }
  set issuecategory(value) {
    super.set("issuecategory", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
  get ticket() {
    return super.get("ticket");
  }
  set ticket(value) {
    super.set("ticket", value);
  }
  get user() {
    return super.get("user");
  }
  set user(value) {
    super.set("user", value);
  }
} OD3_Maintenance_Ticket_Issuecategory.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Ticket_Issuecategory", OD3_Maintenance_Ticket_Issuecategory);
