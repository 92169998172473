import Parse from "parse";























export class OD3_NavigationItem extends Parse.Object {
  static __initStatic() {this.className = "OD3_NavigationItem"}

  constructor(data) {
    super("OD3_NavigationItem", data );
  }

  get activeCondition() {
    return super.get("activeCondition");
  }
  set activeCondition(value) {
    super.set("activeCondition", value);
  }
  get color() {
    return super.get("color");
  }
  set color(value) {
    super.set("color", value);
  }
  get event() {
    return super.get("event");
  }
  set event(value) {
    super.set("event", value);
  }
  get group() {
    return super.get("group");
  }
  set group(value) {
    super.set("group", value);
  }
  get icon() {
    return super.get("icon");
  }
  set icon(value) {
    super.set("icon", value);
  }
  get label() {
    return super.get("label");
  }
  set label(value) {
    super.set("label", value);
  }
  get link() {
    return super.get("link");
  }
  set link(value) {
    super.set("link", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get order() {
    return super.get("order");
  }
  set order(value) {
    super.set("order", value);
  }
  get place() {
    return super.get("place");
  }
  set place(value) {
    super.set("place", value);
  }
  get routeCondition() {
    return super.get("routeCondition");
  }
  set routeCondition(value) {
    super.set("routeCondition", value);
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_NavigationItem.__initStatic();

Parse.Object.registerSubclass("OD3_NavigationItem", OD3_NavigationItem);
