const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\carousel\\carousel.tsx";import React from "react";
import { Carousel } from "antd";
import { Typography } from "antd";
import Minimap from "../minimap/minimap";












const CustomCarousel = ({
  maps,
  bbox,
  updateBbox,
}) => (
  React.createElement(Carousel, { arrows: true, __self: this, __source: {fileName: _jsxFileName, lineNumber: 22}}
    , !maps.length ? (
      React.createElement('div', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 24}}, "No maps available"  )
    ) : (
      maps.map((map, index) => (
        React.createElement('div', { key: index, __self: this, __source: {fileName: _jsxFileName, lineNumber: 27}}
          , React.createElement(Minimap, {
            layerUrls: map.layerUrls,
            bbox: bbox,
            updateBbox: updateBbox, __self: this, __source: {fileName: _jsxFileName, lineNumber: 28}}
          )
          , React.createElement(Typography.Title, { level: 5, style: { fontWeight: "bold" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 33}}
            , map.title
          )
        )
      ))
    )
  )
);

export default CustomCarousel;
