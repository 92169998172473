import Parse from "parse";




















export class OD3_Maintenance_Issuecategory extends Parse.Object {
  static __initStatic() {this.className = "OD3_Maintenance_Issuecategory"}

  constructor(data) {
    super("OD3_Maintenance_Issuecategory", data );
  }

  get catchall() {
    return super.get("catchall");
  }
  set catchall(value) {
    super.set("catchall", value);
  }
  get enabled() {
    return super.get("enabled");
  }
  set enabled(value) {
    super.set("enabled", value);
  }
  get icon() {
    return super.get("icon");
  }
  set icon(value) {
    super.set("icon", value);
  }
  get issuecode() {
    return super.get("issuecode");
  }
  set issuecode(value) {
    super.set("issuecode", value);
  }
  get name() {
    return super.get("name");
  }
  set name(value) {
    super.set("name", value);
  }
  get parent() {
    return super.get("parent");
  }
  set parent(value) {
    super.set("parent", value);
  }
  get source() {
    return super.relation("source");
  }
  get tenant() {
    return super.get("tenant");
  }
  set tenant(value) {
    super.set("tenant", value);
  }
} OD3_Maintenance_Issuecategory.__initStatic();

Parse.Object.registerSubclass("OD3_Maintenance_Issuecategory", OD3_Maintenance_Issuecategory);
