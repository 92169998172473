const _jsxFileName = "C:\\Users\\phung\\Downloads\\ad4gd-open-dash\\src\\js\\components\\InformationPage\\information.tsx";import React from "react";
import {
  Button,
  Card,
  Col,

  Flex,
  Input,
  Row,
  Typography,
} from "antd";
import { WidgetStatic } from "@opendash/plugin-monitoring";
import { useDataService } from "@opendash/plugin-timeseries";
import { ArrowLeftOutlined } from "@ant-design/icons";

import { useNavigate } from "@opendash/router";

const { Search } = Input;

const { Title, Text } = Typography;

const Information = ({ ...data }) => {
  const navigate = useNavigate();
  const DataService = useDataService();
  console.log("data", data);

  DataService.fetchDimensionValuesMultiItem([], {
    historyType: "relative",
    unit: "year",
    value: 2,
  }).then((result) => {
    //Array of Data Points
    console.log("transformed data", result);
    // const transformedData = transformData(result);
    // setData(transformedData);
  });

  return (
    React.createElement(React.Fragment, null
      , React.createElement(Row, { style: { width: "100%", height: "80px" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 40}}
        , React.createElement(WidgetStatic, {
          style: { width: "100%", height: "100%" },
          type: "header-widget",
          config: "", __self: this, __source: {fileName: _jsxFileName, lineNumber: 41}}
)
      )

      , React.createElement(Row, { style: { width: "100%", height: "calc(90vh - 80px)" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 48}}
        , React.createElement(Col, {
          span: 6,
          style: {
            backgroundColor: "white",
            height: "100%",
            paddingLeft: "1%",
          }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 49}}

          , React.createElement(Flex, { vertical: true, justify: "flex-start", align: "flex-start", __self: this, __source: {fileName: _jsxFileName, lineNumber: 57}}
            , React.createElement(Button, {
              icon: React.createElement(ArrowLeftOutlined, {__self: this, __source: {fileName: _jsxFileName, lineNumber: 59}} ),
              iconPosition: "start",
              type: "link",
              style: { color: "#42A456", padding: 0 },
              onClick: () => {
                navigate("/home");
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 58}}
, "Zurück zur Übersicht"

            )
            , React.createElement(Title, {
              level: 1,
              style: {
                fontWeight: "bold",
                width: "100%",
                letterSpacing: "0.25rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 69}}
, "Information"

            )
            , React.createElement(Button, { type: "link", style: { color: "#42A456", padding: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 79}}, "Impressum"

            )
            , React.createElement(Button, { type: "link", style: { color: "#42A456", padding: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 82}}, "Datenschutz"

            )
          )
        )
        , React.createElement(Col, { span: 18, style: { padding: "1%" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 87}}
          , React.createElement(Flex, { vertical: true, justify: "center", align: "center", gap: "small", __self: this, __source: {fileName: _jsxFileName, lineNumber: 88}}
            , React.createElement(Card, {
              title: "Information über das Projekt"   ,
              bordered: false,
              style: {
                width: "100%",
                height: "100%",
                borderRadius: "0.5rem",
              }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 89}}

              , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 98}}, "AD4GD ist ein Projekt, dass sich dem Thema “Green Deal Dataspaces” in drei Pilot-Fällen nähert. Ein Pilot beschäftigt sich mit dem Thema “Biodiversität” insbesondere in der Umgebung um Barcelona. Ein weiterer Pilot beschäftigt sich mit dem Thema “Luftqualität” insbesondere mit der Fragestellung, inwiefern Sensorik die Messungen unterstützen können. Der Pilot zum Thema “Wasser” beschäftigt sich mit Berlin's kleinen Seen und wie mehr Informationen zu diesen gebündelt bereitgestellt werden können. Im Rahmen dieses Piloten wurde das vorliegende Tool generiert."









              )
              , React.createElement(Button, { type: "link", style: { color: "#42A456", padding: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 109}}, "AD4GD Projekt Webseite"

              )
            )

            , React.createElement(Card, {
              title: "Wie wurden die vorliegende Daten erhoben?"     ,
              bordered: false,
              style: { width: "100%", height: "100%", borderRadius: "0.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 114}}

              , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 119}}, "Beschreibung wo die verschiedenen Daten herkommen, wie viele Messstellen es gibt und wer für die verschiedenen Daten zuständig ist."



              )
              , React.createElement(Button, { type: "link", style: { color: "#42A456", padding: 0 }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 124}}, "Wasserportal Berlin"

              )
            )

            , React.createElement(Card, {
              title: "Haftungausschuss",
              bordered: false,
              style: { width: "100%", height: "100%", borderRadius: "0.5rem" }, __self: this, __source: {fileName: _jsxFileName, lineNumber: 129}}

              , React.createElement('p', {__self: this, __source: {fileName: _jsxFileName, lineNumber: 134}}, "tbd")
            )
          )
        )
      )
    )
  );
};

export default Information;
